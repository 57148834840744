import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import PageTitle from '../components/PageTitle'
import Container from '../components/Container'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Logo from '../../static/images/favicon.png'

const Text = styled.p`
  text-align: center;
  line-height: 1;
  a {
    color: ${props => props.theme.colors.text};
    text-decoration: underline;
  }
  img {
    margin: 40px auto;
    width: 20%;
  }
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" description="Page Not Found" />
    <Container>
      <PageTitle>Oouuuups !</PageTitle>
      <Text>
        Cette page semble autant exister que le racisme anti-blanc. <br />
        <img src={Logo} alt="Logo du site" />
        Vous pouvez retourner à la&nbsp;<Link to="/">page d'accueil</Link>&nbsp;
        ou utiliser le menu pour accéder à une autre page.
      </Text>
    </Container>
  </Layout>
)

export default NotFoundPage
